@import "../../styles/mixins/index.scss";

.home {
  position: relative;
  width: 100%;
  height: 100vh;
  background: var(--black) url(../../images/home_bg.png) no-repeat 100% / cover;
  overflow: hidden;
  @include lg {
    height: auto;
    min-height: 100vh;
  }
  &__inner {
    display: flex;
    align-items: center;
    column-gap: 5%;
    height: 100%;
    padding-block-start: 100px;
    @include xxxl {
      padding-block-start: 60px;
    }

    @include lg {
      flex-direction: column;
      padding-top: 100px;
    }
  }
}

.mint {
  max-width: 263px;
  width: 24%;
  padding: 43px 36px;
  border: 1px solid var(--white);
  border-radius: 10px;
  z-index: 9;

  @include lg {
    border: none;
    text-align: center;
    max-width: 300px;
    width: 100%;
  }
  @include sm {
    max-width: 100%;
    padding: 0;
  }
  &__now {
    @include sm {
      background-color: var(--red);
      padding: 22px 0;
    }
  }
}

.mint__img__wrap {
  min-width: 156px;
  min-height: 164px;
  max-width: 190px;
  max-height: 200px;
  margin-bottom: 25px;
  border-radius: 10px;
  overflow: hidden;
  @include lg {
    margin: 20px auto;
  }
}

.mint__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid var(--black);
}

.mint__title {
  margin-bottom: 25px;
  font-family: "Montserrat";
  font-size: 1.75rem;
  line-height: 1.22em;
  font-weight: 700;
  color: var(--white);
  text-align: center;
  text-transform: uppercase;

  @include lg {
    text-align: center;
  }

  @include sm {
    font-size: 1.5rem;
  }
}

.brand {
  max-width: 632px;
  width: 100%;

  @include lg {
    order: -1;
    width: 100%;
  }
  @include sm {
    padding: 0 18px;
  }
}

.brand__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.join__community {
  position: relative;
  width: 24%;
  max-width: 263px;
  height: 100vh;
  transform: translateY(-50px);

  @include lg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 138px;
    transform: translateY(0);
  }
}

.community__inner {
  height: 100%;
  margin: 0 auto;
  padding: 0 36px;
  background-color: var(--red);
}

.community__text {
  font-size: 16px;
  line-height: 1.15em;
  white-space: nowrap;
  transform: translateY(91px);
  text-align: center;

  @include lg {
    display: none;
  }
}

.community__img__wrap {
  position: absolute;
  bottom: 0;
  left: 50%;
  max-width: 410px;
  width: calc(100% + 144px);
  transform: translateX(-50%);

  @include xxl {
    width: 44vmin;
  }

  @include lg {
    left: auto;
    right: 0;
    max-width: 275px;
    width: 48vmin;
    transform: translateX(0) translateY(15%);
  }
}
