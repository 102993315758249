@import '../../styles/mixins/index.scss';

.gallery__item {
    flex-basis: 28%;
    max-width: 210px;

    &__img__wrap {
        max-width: 100%;
        max-height: 210px;
        margin-bottom: 10px;
        border-radius: 10px;
        box-shadow: var(--shadow);
        overflow: hidden;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @include lg {
        flex-basis: calc(33% - 19px);
        max-width: calc(33% - 19px);
    }

    @include sm {
        flex-basis: calc(50% - 13px);
        max-width: calc(50% - 13px);
    }
}

.item__info {
    display: flex;
    justify-content: space-between;
    column-gap: 4%;

    &__name,
    &__number {
        width: 48%;
        font-size: 1rem;
        line-height: 1.15em;
    }

    &__number {
        text-align: right;
    }
}
