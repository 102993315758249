@import "../../styles/mixins/index.scss";

.team__item {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  column-gap: 24px;
  width: calc(100% / 2 - 20px);
  padding: 16px 20px;
  background-color: var(--red);
  border: 1px solid var(--black);
  border-radius: 10px;
  &.margin_top {
    margin-block-start: 42px;

    @include lg {
      margin-block-start: 20px;
      margin-block-end: 20px;
    }
  }

  @include lg {
    width: 60%;

    &:not(.margin_top) {
      margin-left: auto;
    }
  }

  @include md {
    width: 100%;
  }

  @include sm {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    text-align: center;
  }
}

.item__img__wrap {
  @include sm {
    margin-bottom: 20px;
  }
  img {
    width: 216px;
    min-width: 216px;
    height: 216px;
    border-radius: 7px;
    box-shadow: var(--shadow);
  }
}

.item__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid var(--black);
}

.item__info {
  padding-inline-end: 13px;
}

.item__title {
  margin-block-end: 9px;
  font-family: "Montserrat";
  font-size: 1.75rem;
  line-height: 1.22em;
  font-weight: 700;
  color: var(--white);
  text-transform: uppercase;
}

.item__position {
  margin-block-end: 7px;
  font-family: "Montserrat";
  font-size: 1rem;
  line-height: 1.22em;
  font-weight: 700;
  color: var(--white);
  text-transform: uppercase;
}

.item__desc {
  margin-block-end: 24px;
  font-family: "Courier";
  font-size: 1rem;
  line-height: 1.15em;
  color: var(--white);
}

.social__tag {
  font-family: "Courier";
  font-size: 1rem;
  line-height: 1.15em;
  letter-spacing: 0.1em;
  color: var(--black);
}

.item__social {
  display: flex;
  align-items: center;

  @include sm {
    justify-content: center;
  }
}

.social__icon {
  width: 30px;
  height: 25px;
  margin-right: 10px;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
