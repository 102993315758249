@mixin sm {
    @media screen and (max-width: 576px) {
        @content;
    }
}

@mixin md {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin lg {
    @media screen and (max-width: 992px) {
        @content;
    }
}

@mixin xl {
    @media screen and (max-width: 1200px) {
        @content;
    }
}

@mixin xxl {
    @media screen and (max-width: 1320px) {
        @content;
    }
}

@mixin xxxl {
    @media screen and (max-width: 1400px) {
        @content;
    }
}
