@import "../../styles/mixins/index.scss";

.gallery {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: var(--begie);

  &__inner {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding-block-start: 100px;

    @include xxxl {
      padding-block-start: 60px;
    }

    @include lg {
      flex-direction: column;
    }
  }

  &__items__wrap {
    max-width: 964px;
    width: 75%;
    margin-block-start: 67px;

    @include xxxl {
      margin-block-start: 50px;
    }

    @include lg {
      max-width: 100%;
      width: 100%;
      margin-block-start: 0;
    }
  }

  &__items__title {
    margin-block-end: 50px;
    font-family: "Montserrat";
    font-size: 4rem;
    line-height: 1.22em;
    font-weight: 800;
    text-transform: uppercase;

    @include lg {
      display: none;
    }
  }

  &__items__title__mobile {
    display: none;
    margin-block-end: 50px;
    font-family: "Montserrat";
    font-size: 4rem;
    line-height: 1.22em;
    font-weight: 800;
    text-transform: uppercase;

    @include lg {
      order: -2;
      display: block;
    }

    @include sm {
      font-size: 2.5rem;
      text-align: center;
      padding-block-start: 30px;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 38px;
    margin-bottom: 40px;

    @include lg {
      justify-content: center;
      column-gap: 30px;
      max-height: initial;
    }

    @include sm {
      column-gap: 26px;
    }
  }
}

.mint {
  position: relative;
  width: 24%;
  max-width: 263px;
  transform: translateY(-100px);
  z-index: 9;

  &::after {
    content: "";
    display: block;
    background-color: var(--violet);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    @include lg {
      display: none;
    }
  }
  &__inner {
    position: relative;
    height: 100%;
    margin: 0 auto;
    padding-block-start: 131px;
    padding-left: 36px;
    padding-right: 36px;
    background-color: var(--violet);

    @include lg {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 40px;
      height: initial;
      margin-block-end: 40px;
      padding: 20px 26px;
    }

    @include sm {
      flex-direction: column;
      align-items: center;
    }
  }

  &__img__wrap {
    max-width: 190px;
    max-height: 318px;
    margin-block-end: 35px;
    border: 1px solid var(--black);
    box-shadow: var(--shadow);
    border-radius: 10px;
    overflow: hidden;

    @include lg {
      max-width: 156px;
      max-height: 164px;
      margin-block-end: 8px;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    @include lg {
      object-fit: cover;
    }
  }

  @include lg {
    order: -1;
    max-width: initial;
    width: 100vw;
    height: initial;
    transform: translateY(0) translateX(-26px);
  }
}

.mint__details {
  text-align: center;

  &__now {
    margin-block-end: 16px;
    font-family: "Montserrat";
    font-size: 1.75rem;
    line-height: 1.22em;
    font-weight: 700;
    color: var(--white);
    text-transform: uppercase;

    @include lg {
      margin-block-end: 6px;
    }
  }

  &__info {
    margin-block-end: 4px;
    font-size: 1rem;
    line-height: 1.15em;
    text-transform: uppercase;
  }

  &__subtitle {
    margin-block-end: 16px;
    font-family: "Montserrat";
    font-size: 1rem;
    line-height: 1.25em;
    font-weight: 700;
    color: var(--white);
    text-transform: uppercase;

    @include lg {
      margin-block-end: 14px;
    }
  }

  @include lg {
    max-width: 213px;
    text-align: left;
  }

  @include sm {
    text-align: center;
  }
}

.count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-end: 12px;

  &__add,
  &__remove {
    font-size: 1.75rem;
    font-weight: 700;
    cursor: pointer;
  }

  &__value {
    max-width: 50%;
    font-family: "Montserrat";
    font-size: 1.75rem;
    line-height: 1.22em;
    font-weight: 700;
    color: var(--white);
    text-align: center;
  }

  @include lg {
    position: absolute;
    left: calc(50% - 205px);
    bottom: 0;
    width: 156px;
  }

  @include sm {
    position: static;
    margin-left: auto;
    margin-right: auto;
  }
}
