@import '../../styles/mixins/index.scss';

.roadmap {
    position: relative;
    min-height: 100vh;
    background: var(--violet);
    overflow: hidden;
    padding-block-end: 40px;
    @include lg {
        height: initial;
        min-height: 100vh;
        padding-block-end: 40px;
    }

    &:before {
        content: "";
        position: absolute;
        top: 10%;
        right: 0;
        display: block;
        max-width: 306px;
        width: 21%;
        max-height: 306px;
        height: 38%;
        background: url('../../images/roadmap_tool1.png') no-repeat right / contain;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        max-width: 200px;
        width: 14%;
        max-height: 227px;
        height: 28%;
        background: url('../../images/roadmap_tool2.png') no-repeat right bottom / contain;
    }

    &__inner {
        margin-block-start: 67px;
        padding-block-start: 100px;

        &__title {
            margin-block-end: 38px;
            font-family: 'Montserrat';
            font-size: 4rem;
            line-height: 1.22em;
            font-weight: 800;
            color: var(--white);
            text-transform: uppercase;

            @include sm {
                font-size: 2.5rem;
                text-align: center;
            }
        }

        @include lg {
            flex-direction: column;
            margin-block-start: 0;
        }
    }

    &__items__wrap {
        position: relative;
        display: flex;
        column-gap: 32px;
        z-index: 3;

        @include lg {
            flex-wrap: wrap;
        }

        @include md {
            flex-direction: column;
            align-items: center;
            row-gap: 18px;
        }
    }

    &__item__left {
        flex-shrink: 0;
        max-width: 300px;
        width: 24%;
        max-height: 320px;
        overflow: hidden;

        @include md {
            max-width: 100%;
            width: 100%;
        }

        @include sm {
            max-width: 350px;
            min-width: 270px;
            width: 100%;
        }
    }

    &__item__right {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 18px 36px;

        @include lg {
            align-items: flex-start;
        }
    }
}

.item {

    &__inner {
        position: relative;
        border: 1px solid var(--black);
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: var(--black);
            opacity: .5;
        }

        &.item_vision {
            width: 100%;
        }

        &.item_community {
            flex-grow: 1;
            max-width: 410px;
            width: calc(42.5% - 18px);
            max-height: 297px;
        }

        &.item_basement {
            flex-grow: 1;
            max-width: 518px;
            width: calc(57.5% - 18px);
            max-height: 225px;
        }

        &.item_objectives {
            max-width: 740px;
            width: 78%;
            max-height: 157px;
        }

        @include md {
            max-width: 100% !important; 
            width: 100% !important;
            max-height: 320px !important;
        }

      
    }

    &__info__wrap {
        position: absolute;
        top: 50%;
        left: 45px;
        width: calc(100% - 90px);
        transform: translateY(-50%);
        z-index: 3;

        @include md {
            left: 30px;
            width: calc(100% - 60px);
            text-align: center;
        }
    }

    &__inner__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__num {
        margin-block-end: 9px;
        font-family: 'Montserrat';
        font-size: 4rem;
        line-height: 1.22em;
        font-weight: 800;
        color: var(--white);

        @include lg {
            font-size: 2.5rem;
        }

        @include md {
            margin-block-end: 0;
            font-size: 2rem;
        }
    }

    &__arrow__img {

        @include sm {
            margin: 0 auto;
        }
    }

    &__title {
        margin-block-end: 20px;
        font-family: 'Montserrat';
        font-size: 1.75rem;
        line-height: 1.22em;
        font-weight: 800;
        color: var(--white);
        text-transform: uppercase;

        @include lg {
            font-size: 1.5rem;
        }
    }

    &__objectives__child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        width: 100%;

        .item__title {
            margin-block-end: 0;
        }
    }
}