@import "../../styles/mixins/index.scss";

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

.header__inwrap {
  display: flex;
  justify-content: space-between;
  column-gap: 2%;
  padding-top: 26px;
  padding-bottom: 26px;

  @include sm {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.navbar__brand {
  max-width: 84px;
  font-family: "Montserrat";
  max-width: 117px;
  font-size: 2.5rem;
  line-height: 1.2em;
  color: var(--black);
  .header_light & {
    color: var(--white);
  }

  @include lg {
    display: none;
  }
}

.navbar__brand__mobile {
  display: none;
  max-width: 70px;
  font-family: "Montserrat";
  font-size: 2.5rem;
  line-height: 1.2em;
  color: var(--black);

  .header_light & {
    color: var(--white);
  }

  @include lg {
    display: block;
  }
}

.header__inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @include lg {
    position: fixed;
    top: 0;
    right: 0;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding-block-start: 40px;
    padding-block-end: 20px;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    transform: translateX(110%);
    transition: transform 0.3s;
    background-color: var(--black);
    overflow: hidden auto;

    &.mobile_menu_opened {
      transform: translateX(0);
    }
  }

  @include md {
    width: 100%;
  }
}

.nav {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  column-gap: 30px;
  width: calc(74% - 120px);

  &__link {
    padding: 12px 20px;
    font-size: 1rem;
    line-height: 1.15em;
    letter-spacing: 0.1em;
    color: var(--black);
    background-color: transparent;
    border: 1px solid var(--black);
    border-radius: 7px;
    text-transform: uppercase;
    transition: background-color 0.24s, box-shadow 0.24s, border-color 0.24s;
    @include lg {
      color: var(--white);
      border-color: var(--white);
    }
    .header_light & {
      color: var(--white);
      border-color: var(--white);
    }

    &:hover {
      background-color: var(--orange);
      border-color: var(--orange);
      box-shadow: var(--shadow);
    }

    @include xl {
      padding: 6px 10px;
    }

    @include lg {
      padding: 12px 20px;
    }
  }

  @include lg {
    .mobile_menu_opened & {
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 0;
      row-gap: 30px;
      width: 100%;
    }
  }

  @include md {
    .mobile_menu_opened & {
      align-items: center;
    }
  }
}

.social {
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 30px;
  max-width: 263px;
  width: 22%;
  padding: 0 2%;
  &__button {
    display: flex;
    width: 44px;
    height: 40px;
    border: 1px solid var(--white);
    border-radius: 7px;
    box-shadow: var(--shadow);
    transition: background-color 0.24s, box-shadow 0.24s, border-color 0.24s;

    &:hover {
      background-color: var(--orange);
      border-color: var(--orange);
      box-shadow: var(--shadow);
    }
  }

  &__icon {
    height: 18px;
    margin: auto;
    color: var(--white);
  }

  @include xl {
    column-gap: 5px;
    width: 23.5%;
  }

  @include lg {
    column-gap: 30px;
  }

  @include lg {
    .mobile_menu_opened & {
      flex-grow: 0;
      justify-content: flex-start;
      max-width: 100%;
      width: 100%;
      padding: 0;
    }
  }

  @include md {
    .mobile_menu_opened & {
      justify-content: center;
    }
  }
}

.nav__burger {
  display: none;
  position: absolute;
  top: 40px;
  right: 30px;
  width: 26px;
  height: 20px;
  cursor: pointer;
  z-index: 99;

  &:before,
  &:after,
  .burger__line {
    position: absolute;
    left: 0;
    width: 24px;
    height: 2px;
    background-color: var(--white);
    transition: transform 0.24s, opacity 0.24s;

    .header_light & {
      background-color: var(--white);
    }

    .mobile_menu_opened + & {
      background-color: var(--white);
    }
  }

  &:before {
    content: "";
    bottom: 0;
  }

  &:after {
    content: "";
    top: 0;
  }

  .burger__line {
    top: 9px;
  }

  &.opened {
    .burger__line {
      opacity: 0;
    }

    &:before {
      transform: rotate(45deg) translate(-7px, -6px);
    }

    &:after {
      transform: rotate(-45deg) translate(-7px, 6px);
    }
  }

  @include xl {
    right: 30px;
  }

  @include lg {
    display: block;
    right: 16px;
  }

  @include md {
    right: 24px;
  }

  @include sm {
    top: 35px;
    right: 18px;
  }
}
