@import '../mixins/index.scss';

:root {
    --black: #000000;
    --white: #FFFFFF;
    --begie: #EDEAE7;
    --red: #C84F4F;
    --orange: #FA6C3D;
    --violet: #6461E2;
    --shadow: 0 4px 9px rgba(0, 0, 0, 0.25)
}

.container {
    max-width: 1440px;
    width: 100%;
    padding: 0 72px;
    margin: 0 auto;

    @include xl {
        padding: 0 30px;
    }

    @include lg {
        padding: 0 16px;
    }

    @include md {
        padding: 0 24px;
    }

    @include sm {
        padding: 0 18px;
    }
}

.container-lg {
    max-width: 1440px;
    width: 100%;
    padding: 0 72px;
    margin: 0 auto;

    @include xl {
        padding: 0 30px;
    }

    @include lg {
        padding: 0 16px;
    }

    @include md {
        padding: 0 24px;
    }

    @include sm {
        padding: 0;
    }
}

@font-face {
    font-family: 'Courier';
    src: local('Courier Regular'), local('Courier-Regular'),
        url('../fonts/Courier/CourierStd.woff2') format('woff2'),
        url('../fonts/Courier/CourierStd.woff') format('woff'),
        url('../fonts/Courier/CourierStd.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

html {
    font-size: 16px;
}

body {
    font-family: 'Courier';
    // background-color: var(--black);
}

a {
    color: var(--black);
}

.btn__connect__wallet {
    max-width: 212px;
    width: 100%;
    height: 50px;
    font-family: 'Montserrat';
    font-size: 1rem;
    font-weight: 700;
    color: var(--white);
    background-color: var(--violet);
    border: 1px solid var(--black);
    box-shadow: var(--shadow);
    border-radius: 10px;
    text-transform: uppercase;

    @include sm {
        padding: 0 5px;
    }
}

.nav__link {

    &.active.active {
        background-color: var(--orange);
        border-color: var(--orange);
        box-shadow: var(--shadow);
        color: var(--white);
    }
}

.fade {
    &-enter {
        opacity: 0;
    }

    &-exit {
        opacity: 1;
    }

    &-exit.fade-exit-active {
        opacity: 0;
        transition: opacity 400ms ease-out;
    }

    &-enter.fade-enter-active {
        opacity: 1;
        transition: opacity 400ms ease-out;
    }

}

.d-none{
    display: none !important;
}