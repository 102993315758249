@import "../../styles/mixins/index.scss";

.our__barber {
  position: relative;
  width: 100%;
  background-color: var(--black);
  overflow: hidden;
  &__inner {
    display: flex;
    justify-content: space-between;
    column-gap: 2%;
    padding-block-start: 100px;
    @include lg {
      flex-direction: column;
      padding-block-end: 20px;
      height: initial;
    }
  }
}

.our__barber_left {
  max-width: 964px;
  width: 68%;
  margin-block-start: 67px;
  padding-block-end: 100px;
  @include xxxl {
    margin-block-start: 50px;
  }

  @include xl {
    margin-block-start: 20px;
  }

  @include lg {
    max-width: 100%;
    width: 100%;
    min-height: calc(100vh - 450px);
    margin-block-start: 0;
    margin-block-end: 40px;
  }

  @include md {
    height: initial;
    min-height: calc(100vh - 300px);
    padding-block-end: 40px;
  }

  @include sm {
    height: initial;
    min-height: calc(100vh - 250px);
    padding-block-end: 40px;
  }
}

.our__barber__title {
  margin-block-end: 44px;
  font-family: "Montserrat";
  font-size: 4rem;
  line-height: 1.22em;
  font-weight: 800;
  color: var(--white);
  text-transform: uppercase;

  @include sm {
    font-size: 2.5rem;
    text-align: center;
  }
}

.our__barber__description {
  min-height: 160px;
  font-size: 24px;
  line-height: 1.15em;
  color: var(--white);

  @include sm {
    font-size: 1rem;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(#fff, 0.3);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(#fff, 0.2);
    border-radius: 3px;
  }

  @include lg {
    max-height: initial;
    overflow-y: visible;
  }
}

.join__community {
  position: relative;
  width: 24%;
  max-width: 263px;
  z-index: 9;
  &::before {
    content: "";
    background-color: var(--violet);
    position: absolute;
    top: -100px;
    width: 100%;
    height: 100px;
    @include lg {
        display: none;
    }
  }
  @include lg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 150px;
    transform: translateY(0);
  }

  @include sm {
    height: 100px;
  }
}

.community__inner {
  height: 100%;
  margin: 0 auto;
  padding: 0 36px;
  background-color: var(--violet);
  @include lg {
    display: none;
  }
}

.community__text {
  font-size: 16px;
  line-height: 1.15em;
  white-space: nowrap;
  transform: translateY(91px);
  text-align: center;

  @include lg {
    display: none;
  }
}

.community__img__wrap {
  position: absolute;
  bottom: 0;
  left: 50%;
  max-width: 410px;
  width: calc(100% + 144px);
  transform: translateX(-50%);
  overflow: hidden;

  @include xxl {
    width: 44vmin;
  }

  @include lg {
    left: 50%;
    max-width: 275px;
    width: 48vmin;
    transform: translateX(-50%) translateY(15%);
  }
}

.barber_bg {
  img {
    width: 100%;
    height: 100vh;
    @include sm {
      height: 220px;
    }
  }
}
