@import "../../styles/mixins/index.scss";

.roadmapsub {
  position: relative;
  width: 100%;
  height: 100vh;
  background: var(--violet);
  overflow: hidden;

  &__inner {
    display: flex;
    justify-content: space-between;
    column-gap: 2%;
    margin-block-start: 67px;
    padding-block-start: 100px;
    transition: transform 0.4s;
    transform: translate(100vw);

    @include xxxl {
      margin-block-start: 50px;
      padding-block-start: 60px;
    }

    @include lg {
      flex-direction: column;
      margin-block-start: 0;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    max-width: 964px;
    width: 74%;

    @include lg {
      max-width: 100%;
      width: 100%;
      margin-block-start: 0;
    }
  }

  &__title {
    margin-block-end: 38px;
    font-family: "Montserrat";
    font-size: 4rem;
    line-height: 1.22em;
    font-weight: 800;
    color: var(--white);
    text-transform: uppercase;

    @include lg {
      display: none;
    }
  }

  &__title__mobile {
    display: none;
    margin-block-end: 50px;
    font-family: "Montserrat";
    font-size: 4rem;
    line-height: 1.22em;
    font-weight: 800;
    text-transform: uppercase;

    @include lg {
      order: -2;
      display: block;
    }

    @include sm {
      font-size: 2.5rem;
      text-align: center;
    }
  }

  &__details {
    position: relative;
    flex-grow: 1;
    padding: 40px 45px;
    border: 1px solid var(--black);
    box-shadow: var(--shadow);
    border-radius: 10px;
    animation: toLeft 0.6s both;
    @keyframes toLeft {
      0% {
        transform: translateX(-100vw);
        opacity: 0;
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
    @include lg {
      max-height: initial;
    }

    @include md {
      padding: 20px;
    }
  }

  &__num {
    margin-block-end: 8px;
    font-family: "Montserrat";
    font-size: 4rem;
    line-height: 1.22em;
    font-weight: 800;
    color: var(--black);

    @include sm {
      text-align: center;
    }
  }

  &__subtitle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 20px;
    margin-block-end: 25px;
    font-family: "Montserrat";
    font-size: 1.75rem;
    line-height: 1.22em;
    font-weight: 800;
    color: var(--white);
    text-transform: uppercase;

    @include sm {
      justify-content: center;
    }
  }

  &__exploring {
    font-family: "Courier";
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 2px 8px;
    color: var(--black);
    background-color: var(--orange);
    border-radius: 4px;
    text-transform: uppercase;

    @include sm {
      margin-inline-start: 0;
    }
  }

  &__desc {
    p {
      line-height: 1.15em;
      margin-bottom: 20px;
    }
    ul {
      padding-left: 28px;
      li {
        position: relative;
        &::before {
          content: "";
          background-image: url(http://localhost:3000/static/media/scissors.4bafd27c3051524b1ff1.svg);
          background-repeat: no-repeat;
          display: block;
          height: 20px;
          width: 20px;
          position: absolute;
          left: -24px;
          top: 50%;
          transform: translateY(-50%);
          background-size: contain;
        }
        margin-bottom: 20px;
      }
    }
  }

  &__desc__arrow {
    position: absolute;
    right: 35px;
    bottom: 15px;
    cursor: pointer;
    @include md {
      bottom: 4px;
    }
  }

  @include lg {
    height: initial;
    min-height: 100vh;
    padding-block-end: 40px;
    overflow-y: auto;
  }
}

.article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 24px;
  max-width: 300px;
  width: 24%;
  flex-shrink: 0;

  &__sub {
    position: relative;
    flex-shrink: 0;
    max-height: 170px;
    border: 1px solid var(--black);
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    animation: fadeIn 0.5s both;
    @keyframes fadeIn {
      0% {
        transform: translateX(100vw);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: var(--black);
      opacity: 0.5;
    }

    @include xl {
      height: 130px;
    }
    @include sm {
      width: 100%;
    }
  }

  &__sub__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
    @include xl {
      object-fit: cover;
    }
  }

  &__sub__wrap {
    position: absolute;
    top: 50%;
    left: 34px;
    width: calc(100% - 68px);
    transform: translateY(-50%);
    z-index: 3;

    @include xl {
      left: 20px;
      width: calc(100% - 40px);
    }
  }

  &__sub__num {
    font-family: "Montserrat";
    font-size: 3.5rem;
    line-height: 1.22em;
    font-weight: 800;
    color: var(--white);

    @include xl {
      font-size: 2.5rem;
    }
  }

  &__sub__title {
    font-family: "Montserrat";
    font-size: 1.5rem;
    line-height: 1.22em;
    font-weight: 800;
    color: var(--white);
    text-transform: uppercase;
  }

  @include lg {
    order: -1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 20px;
    max-width: 100%;
    width: 100%;
    margin-block-end: 40px;
  }
}
