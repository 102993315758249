@import '../../styles/mixins/index.scss';

.meet__the__team {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding-block-end: 40px;
    background: var(--red);
    overflow: hidden;


    &__inner {
        margin-block-start: 67px;
        padding-block-start: 100px;

        @include xxxl {
            margin-block-start: 50px;
            padding-block-start: 60px;
        }

        @include lg {
            flex-direction: column;
            margin-block-start: 0;
        }
    }

    &:after {
        content: "";
        position: absolute;
        top: 15%;
        right: 0;
        display: block;
        max-width: 263px;
        width: 19%;
        max-height: 365px;
        height: 45%;
        background: url('../../images/tools.png') no-repeat right / contain;
    }

    @include lg {
        height: initial;
        min-height: 100vh;
        padding-block-end: 40px;
    }
}

.meet__the__team__title {
    font-family: 'Montserrat';
    font-size: 4rem;
    line-height: 1.22em;
    font-weight: 800;
    color: var(--white);
    text-transform: uppercase;

    @include sm {
        font-size: 2.5rem;
        text-align: center;
        margin-block-start: 30px;
    }
}

.team__items__wrap {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-block-start: 40px;
}

.team__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1074px;
    width: 90%;
    flex-wrap: wrap;
    @include lg {
        flex-wrap: wrap;
        width: 100%;
    }
}